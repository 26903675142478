import { HttpClient } from "@angular/common/http";
import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { environment } from "src/environments/environment";
import { GameService } from "../services/game.service";
import Noty from 'noty';
import { LocaleService } from "../services/locale.service";
import { skip } from "rxjs/operators";
@Component({
    selector: "app-review",
    templateUrl: "./review.component.html",
    styleUrls: ["./review.component.scss"],
})
export class ReviewComponent implements OnInit {
    rating = 0;
    comment = "";
    c = "";
    g = "";
    p = "";
    groupId = "";
    alreadyReviewed = false;
    game;
    locales = {
        opinion: this.localeService.getTrans("Your opinion helps us make the game even better for you!"),
        next: this.localeService.getTrans("Next"),
        skip: this.localeService.getTrans("Skip"),
        placeholder: this.localeService.getTrans("Share your thoughts about the game..."),
    }

    constructor(
        private http: HttpClient,
        private route: ActivatedRoute,
        private router: Router,
        public gameService: GameService,
        private localeService: LocaleService
    ) {
    }

    getGame(gameId, player) {
        return this.http
          .post(environment.baseUrl + 'games/getGame', { gameId, player })
          .subscribe((data: any) => {
            if (data.success) {
              this.game = data.data;
              console.log(this.game);
              this.amIReviewed();
              this.setRating(4)
            }
          });
      }

    dropNoty(type, text, clickEvent?, timeout?) {
        new Noty({
          type: type,
          text: text,
          killer: true,
          timeout,
        }).show();
      }

    ngOnInit() {
        this.route.paramMap.subscribe(params => {
            this.c = params.get('c') || '';
            this.g = params.get('g') || '';
            this.p = params.get('p') || '';
            this.groupId = params.get('groupId') || '';
            console.log(params);
            this.getGame(this.g, this.p);
           
        });
    }
    

    setRating(rating: number) {
        this.rating = rating;
    }

    amIReviewed(){
        this.http.post(environment.baseUrl + 'review/amIReviewed', { 
            groupId: this.groupId,
            user: this.p 
        }).subscribe(
            (data: any) => {
               if(data.count){
                this.alreadyReviewed = true;
                this.dropNoty('error', 'You have already reviewed this game!');
               }
            },
            (error) => {
                console.error('Error:', error);
                alert('Something went wrong!');
            }
        );
    }

    navigateToLeaderboard(){
        this.router.navigate(['/endGame', { g: this.g, p: this.p , groupId: this.groupId }]);
    }

    sendReview() {
        if (this.rating === 0) {
            this.dropNoty('error', 'Please set a rating!');
            return;
        }
        if (this.comment === "") {
            this.dropNoty('error', 'Please set a comment!');
            return;
        }

        console.log(this.game)

        this.http.post(environment.baseUrl + 'review/addReview', { 
            comment: this.comment, 
            rating: this.rating, 
            groupId: this.groupId,
            tourId: this.game.tourId._id,
            partner: this.game.tourId.partner,
            gameId: this.game._id,
            user: this.p 
        }).subscribe(
            (data: any) => {
                if (data.success) {
                    this.router.navigate(['/endGame', { c: this.c, g: this.g, p: this.p, groupId: this.groupId }]);
                }
            },
            (error) => {
                console.error('Error:', error);
                alert('Something went wrong!');
            }
        );
        
}
}