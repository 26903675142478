import { getLocalePluralCase } from '@angular/common';
import { Injectable } from '@angular/core';
import * as _ from 'lodash';
@Injectable({
    providedIn: 'root'
})
export class MobileAppService {
    isTest = false;
    constructor() { }

    getURL() {
        if (this.isTest) {
            return 'https://flinkit-app-v3-swap.azurewebsites.net/';
        } else{
            return 'https://outdoor.flinkit.io/';

        }
    }

    getURLByPartner(partner) {
        if (this.isTest) {
            return 'https://flinkit-app-v3-swap.azurewebsites.net/';
        } else if(partner.partnerURL){
            return partner.partnerURL;
        }
         else{
            return 'https://outdoor.flinkit.io/';
        }
    }

}