<!-- Map container  (click)="mapClicked($event)" -->

<div class="test-bar" *ngIf="gameService.activeGame && gameService.activeGame.isTest">
  Test game. Only for testing purposes. Not for sale!
</div>

<div id="fi-map-container" [class.naked]="gameService.activeGame.tourId.isNaked" [style.background-color]="gameService.activeGame.tourId.isNaked ? bgColor1 : null" *ngIf="gameService.activeGame && (gameService.activeGame.tourId.isQR ? activeBounds?.length : true)">
  
  
  <div class="row brand" *ngIf="gameService.activeGame.tourId.isNaked && !gameService.activeGame.partner.isMuseum && gameService.activeGame && gameService.activeGame.partner && gameService.activeGame.partner.isExclusiveBranded">
    <div class="col-12 text-center fi-text-bold">
      <img [src]="gameService.activeGame.tourId.isCustomBranding ? gameService.activeGame.tourId.customBranding.cover : ((gameService.activeGame.partner && gameService.activeGame.partner.logo) ? gameService.activeGame && gameService.activeGame.partner.logo : './assets/images/pbe.png')"  width="150"alt="">
    </div>
  </div>

  
  <div id="egg" *ngIf="activePerks.blank_map">
    <img src="./assets/images/egg.png" alt="">
  </div>

  <mgl-map *ngIf="!gameService.activeGame.tourId.isNaked" (load)="map = $event" [movingMethod]="'jumpTo'" [fitBounds]="gameService.activeGame.tourId.isQR ? activeBounds : undefined" (click)="mapClicked($event)" [style]="mapStyle" [minZoom]="6" [maxZoom]="21" [zoom]="pageConfig.zoom" >
    
<!--     <mgl-image-source *ngFor="let layer of activeLayers"
    id="museum_layer_{{layer.url}}"
    type="image"
    [url]="layer.url"
    [coordinates]="layer.coordinates"></mgl-image-source>

    <mgl-layer *ngFor="let layer of activeLayers"
    [id]="'layer_'+layer.url"
    type="raster"
    [source]="'museum_layer_'+layer.url"
  ></mgl-layer> -->

    <mgl-layer
    id="state-borders"
    type="line"
    [pitch]="[30]"
    [source]="gameService.battleCircle"
    [paint]="{
      'line-color': '#627BC1',
      'line-width': 20
    }"
  ></mgl-layer>



    <!-- Me marker -->
    <mgl-marker *ngIf="gameService.me && gameService.me.position" [lngLat]="[gameService.me.position.lng, gameService.me.position.lat]">
      <div class="fi-map-marker-me" [ngStyle]="{'background': 'url(./assets/images/pois/'+ (localeService.locale ? localeService.locale : 'en')+'.png)',
      'background-size': '50px',
      'background-repeat': 'no-repeat'}">
      </div>
    </mgl-marker>
    <!-- End of Me marker -->

      <!-- End of Enemy marker -->
        <!-- Question marker -->
        <div *ngIf="gameService.activeGame.tourId.isQR else normalMarker">
          <mgl-marker *ngFor="let quest of getGameQuests(); let i = index; trackBy:trackByQuests" (click)="dropTooltip('./assets/images/pois/question-marker.png', 'Question','Answering questions earns you  '+quest.points+' points. Approach it to activate.')" [lngLat]="[quest.lng, quest.lat]" [className]="(gameService.activeGame?.tourId?.isSequenceGame && quest?.isUpcoming && i!==0) ? 'opaque' : ''">
            <div class="habos" [class]="(gameService.activeGame.tourId.isQR ? ( quest.isQuestion ? 'fi-map-marker-question qr' : 'fi-map-marker-question info-marker' ) : 'fi-map-marker-question question-marker') + (quest.isVisible ? '' : ' invisibleMarker')">
              <div class="index-counter" *ngIf="gameService.activeGame?.tourId?.isSequenceGame">{{(i+1) + (gameService.activeGame.tourId.isSequenceGame ? gameService.me.passedQuests.length : 0)}}</div>
            </div>
          </mgl-marker>
        </div>
       <!--  class="{{(gameService.activeGame.tourId.isSequenceGame && i>gameService.me.passedQuests.length ) ? 'opaque' : ''}}" -->
        <ng-template #normalMarker>
          <mgl-marker *ngFor="let quest of getGameQuests(); let i = index"  [lngLat]="[quest.lng, quest.lat]" [className]="(gameService.activeGame?.tourId?.isSequenceGame && quest?.isUpcoming && i!==0 ) ? 'opaque' : ''">
           <!--  <div   [class]="(gameService.activeGame.tourId.isQR ? 'fi-map-marker-question qr' : (quest.isQuestion ? (quest.isEndPoi ? 'fi-map-marker-question finish-marker' : 'fi-map-marker-question question-marker') : 'fi-map-marker-question info-marker' )) + (quest.isVisible ? '' : ' invisibleMarker')">
              <div class="index-counter" *ngIf="gameService.activeGame?.tourId?.isSequenceGame">{{(i+1) + (gameService.activeGame.tourId.isSequenceGame ? gameService.me.passedQuests.length : 0)}}</div>
            </div> -->

            <div class="fl-svg-marker-container" >
              <div class="index-counter" *ngIf="gameService.activeGame?.tourId?.isSequenceGame">{{(i+1) + (gameService.activeGame.tourId.isSequenceGame ? gameService.me.passedQuests.length : 0)}}</div>
              <div *ngIf="!gameService.activeGame.tourId.isQR && !quest.isQuestion && !quest.isEndPoi">
                

                <svg
xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="60" height="60" viewBox="0,0,256,256"
style="fill:#000000;">
<g fill="none" fill-rule="nonzero" stroke="none" stroke-width="none" stroke-linecap="none" stroke-linejoin="none" stroke-miterlimit="10" stroke-dasharray="" stroke-dashoffset="0" font-family="none" font-weight="none" font-size="none" text-anchor="none" style="mix-blend-mode: normal">
  <g transform="scale(2.56,2.56)">
    <path d="M52,96c-1.7,0 -3.4,-0.7 -4.6,-1.9c-3.1,-3.1 -30.7,-31.3 -30.6,-52.5c0,-20.1 18.2,-33.6 35.2,-33.6c17,0 35.2,13.5 35.2,33.6c0,21.2 -27.5,49.3 -30.6,52.5c-1.2,1.2 -2.9,1.9 -4.6,1.9z" fill="#000000" stroke="none" stroke-width="1" stroke-linecap="butt" stroke-linejoin="miter" opacity="0.35"></path>
    <path d="M50,94c-1.7,0 -3.4,-0.7 -4.6,-1.9c-3.1,-3.1 -30.7,-31.3 -30.6,-52.5c0,-20.1 18.2,-33.6 35.2,-33.6c17,0 35.2,13.5 35.2,33.6c0,21.2 -27.5,49.3 -30.6,52.5c-1.2,1.2 -2.9,1.9 -4.6,1.9z" fill="#f2f2f2" stroke="none" stroke-width="1" stroke-linecap="butt" stroke-linejoin="miter"></path>
    <path d="M50,12.5c-14,0 -28.7,11.2 -28.7,27.1c-0.1,19.1 28.7,47.9 28.7,47.9c0,0 28.8,-28.7 28.7,-47.9c0,-15.9 -14.7,-27.1 -28.7,-27.1z" [attr.fill]="gameService.activeGame.tourId.isCustomMarkers ? quest.color : '#707cc0'"  stroke="#40396e" stroke-width="3" stroke-linecap="round" stroke-linejoin="round"></path>
    <path d="M68,40l-7.6,-3.6v0l2.8,-7.7c0.1,-0.2 0,-0.5 -0.2,-0.7c-0.1,-0.1 -0.3,-0.2 -0.5,-0.2c-0.1,0 -0.2,0 -0.2,0l-7.3,2.7l-3.7,-7.3c-0.1,-0.2 -0.3,-0.4 -0.6,-0.4v0c-0.3,0 -0.5,0.1 -0.6,0.4l-3.8,7.3l-7.6,-2.7c-0.1,0 -0.2,0 -0.2,0c-0.2,0 -0.3,0.1 -0.5,0.2c-0.2,0.2 -0.3,0.4 -0.2,0.7l2.4,7.9l-7.5,3.4c-0.2,0.1 -0.4,0.3 -0.4,0.6c0,0.2 0.2,0.5 0.4,0.6l7.3,3.5l-2.4,7.9c-0.1,0.2 0,0.5 0.2,0.7c0.1,0.1 0.3,0.2 0.5,0.2c0.1,0 0.2,0 0.2,0l7.6,-2.7l3.8,7.2c0.1,0.2 0.4,0.4 0.6,0.4v0c0.3,0 0.5,-0.2 0.6,-0.4l3.7,-7.2l7.3,2.7c0.1,0 0.2,0 0.2,0c0.2,0 0.4,-0.1 0.5,-0.2c0.2,-0.2 0.2,-0.5 0.2,-0.7l-2.8,-7.7v0l7.6,-3.6c0.2,-0.1 0.4,-0.3 0.4,-0.6c0.1,-0.4 0,-0.6 -0.2,-0.7z" [attr.fill]="gameService.activeGame.tourId.isCustomMarkers ? quest.color : '#707cc0'" stroke="none" stroke-width="1" stroke-linecap="butt" stroke-linejoin="miter"></path>
    <circle cx="50.4" cy="40.5" r="6.5" [attr.fill]="gameService.activeGame.tourId.isCustomMarkers ? quest.color : '#707cc0'" stroke="none" stroke-width="1" stroke-linecap="butt" stroke-linejoin="miter"></circle>
  </g>
</g>
<g fill="#ffffff" fill-rule="nonzero" stroke="none" stroke-width="1" stroke-linecap="butt" stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="" stroke-dashoffset="0" font-family="none" font-weight="none" font-size="none" text-anchor="none" style="mix-blend-mode: normal">
  <g>
    <path d="M134.994,95.547v54.1h-14.5v-54.1zM119.644,81.547v0c0,-2.16667 0.72667,-3.95 2.18,-5.35c1.44667,-1.4 3.42,-2.1 5.92,-2.1v0c2.46667,0 4.43333,0.7 5.9,2.1c1.46667,1.4 2.2,3.18333 2.2,5.35v0c0,2.2 -0.74,4 -2.22,5.4c-1.48667,1.4 -3.44667,2.1 -5.88,2.1c-2.43333,0 -4.39,-0.7 -5.87,-2.1c-1.48667,-1.4 -2.23,-3.2 -2.23,-5.4z"></path>
  </g>
</g>
</svg>

                
                
              </div>
              <div *ngIf="!gameService.activeGame.tourId.isQR && quest.isQuestion && quest.isEndPoi">
                <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="60" height="60" viewBox="0 0 100 100">
                  <path d="M52,96c-20.208,0-41.958-6.161-41.958-19.688c0-8.249,8.299-14.646,22.588-17.734	c-5.881-9.89-8.753-17.369-8.753-22.749C23.876,20.484,36.492,8,52,8s28.124,12.484,28.124,27.829c0,5.38-2.872,12.859-8.753,22.749	c14.289,3.089,22.588,9.485,22.588,17.734C93.958,89.839,72.208,96,52,96z" opacity=".35"></path>
                  <path fill="#f2f2f2" d="M50,94C29.792,94,8.042,87.839,8.042,74.312c0-8.249,8.299-14.646,22.588-17.734	c-5.881-9.89-8.753-17.369-8.753-22.749C21.876,18.484,34.492,6,50,6s28.124,12.484,28.124,27.829c0,5.38-2.872,12.859-8.753,22.749	c14.289,3.089,22.588,9.485,22.588,17.734C91.958,87.839,70.208,94,50,94z"></path>
                  <path [attr.fill]="'#97c363'" d="M50,61.125c-19.583,0-35.458,5.904-35.458,13.187C14.542,81.595,30.417,87.5,50,87.5	s35.458-5.904,35.458-13.187C85.458,67.029,69.583,61.125,50,61.125z"></path>
                  <path [attr.fill]="'#f4665d'" d="M50,13.866c-11.138,0-20.167,8.906-20.167,19.892C29.833,44.745,50,70.699,50,70.699	s20.167-25.954,20.167-36.942C70.167,22.772,61.138,13.866,50,13.866z"></path>
                  <path fill="none" stroke="#40396e" stroke-miterlimit="10" stroke-width="3" d="M58.541,61.513	c6.046-9.021,13.083-20.962,13.083-27.684C71.624,22.05,61.942,12.5,50,12.5s-21.624,9.55-21.624,21.329	c0,6.722,7.037,18.663,13.083,27.684c-15.456,1.422-26.918,6.612-26.918,12.8C14.542,81.595,30.417,87.5,50,87.5	s35.458-5.904,35.458-13.187C85.458,68.125,73.997,62.935,58.541,61.513z"></path>
                  <circle cx="49.937" cy="34.061" r="10.187" fill="#f2f2f2"></circle>
                </svg>
              </div>
              <div *ngIf="!gameService.activeGame.tourId.isQR && quest.isQuestion && quest?.password?.length > 0">

                <svg xmlns="http://www.w3.org/2000/svg"  viewBox="0 0 48 48" width="48px" height="48px">
                    <path fill="#424242" d="M24,4c-5.5,0-10,4.5-10,10v4h4v-4c0-3.3,2.7-6,6-6s6,2.7,6,6v4h4v-4C34,8.5,29.5,4,24,4z"/>
                    <path fill="#FB8C00" d="M36,44H12c-2.2,0-4-1.8-4-4V22c0-2.2,1.8-4,4-4h24c2.2,0,4,1.8,4,4v18C40,42.2,38.2,44,36,44z"/>
                    <path fill="#C76E00" d="M24 28A3 3 0 1 0 24 34A3 3 0 1 0 24 28Z"/>
                  </svg>

              </div>
              <div *ngIf="!gameService.activeGame.tourId.isQR && quest.isQuestion && !quest.isEndPoi && !quest?.password" (click)="dropPointInfoNoty(quest?.points)">
                
                <svg
	xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="60" height="60" viewBox="0,0,256,256"
style="fill:#000000;">
	<g fill="none" fill-rule="nonzero" stroke="none" stroke-width="none" stroke-linecap="none" stroke-linejoin="none" stroke-miterlimit="10" stroke-dasharray="" stroke-dashoffset="0" font-family="none" font-weight="none" font-size="none" text-anchor="none" style="mix-blend-mode: normal">
		<g transform="scale(2.56,2.56)">
			<path d="M52,96c-1.7,0 -3.4,-0.7 -4.6,-1.9c-3.1,-3.1 -30.7,-31.3 -30.6,-52.5c0,-20.1 18.2,-33.6 35.2,-33.6c17,0 35.2,13.5 35.2,33.6c0,21.2 -27.5,49.3 -30.6,52.5c-1.2,1.2 -2.9,1.9 -4.6,1.9z" fill="#000000" stroke="none" stroke-width="1" stroke-linecap="butt" stroke-linejoin="miter" opacity="0.35"></path>
			<path d="M50,94c-1.7,0 -3.4,-0.7 -4.6,-1.9c-3.1,-3.1 -30.7,-31.3 -30.6,-52.5c0,-20.1 18.2,-33.6 35.2,-33.6c17,0 35.2,13.5 35.2,33.6c0,21.2 -27.5,49.3 -30.6,52.5c-1.2,1.2 -2.9,1.9 -4.6,1.9z" fill="#f2f2f2" stroke="none" stroke-width="1" stroke-linecap="butt" stroke-linejoin="miter"></path>
			<path d="M50,12.5c-14,0 -28.7,11.2 -28.7,27.1c-0.1,19.1 28.7,47.9 28.7,47.9c0,0 28.8,-28.7 28.7,-47.9c0,-15.9 -14.7,-27.1 -28.7,-27.1z" [attr.fill]="gameService.activeGame.tourId.isCustomMarkers ? quest.color : '#f4665c'" stroke="none" stroke="#40396e" stroke-width="3" stroke-linecap="round" stroke-linejoin="round"></path>
			<path d="M61.1,36.8h-10.1l7.7,-14.5h-10.9l-8.9,21.7h9.2l-6.8,17z" [attr.fill]="gameService.activeGame.tourId.isCustomMarkers ? quest.color : '#f4665c'" stroke="none" stroke-width="1" stroke-linecap="butt" stroke-linejoin="miter"></path>
		</g>
	</g>
	<g fill="#ffffff" fill-rule="nonzero" stroke="none" stroke-width="1" stroke-linecap="butt" stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="" stroke-dashoffset="0" font-family="none" font-weight="none" font-size="none" text-anchor="none" style="mix-blend-mode: normal">
		<g>
			<path d="M133.119,126.122h-12.8c0,-4.66667 0.56667,-8.38333 1.7,-11.15c1.13333,-2.76667 3.21,-5.49 6.23,-8.17c3.01333,-2.68667 5.02,-4.87 6.02,-6.55c1,-1.68667 1.5,-3.46333 1.5,-5.33v0c0,-5.63333 -2.6,-8.45 -7.8,-8.45v0c-2.46667,0 -4.44333,0.76 -5.93,2.28c-1.48,1.51333 -2.25333,3.60333 -2.32,6.27v0h-14.5c0.06667,-6.36667 2.12667,-11.35 6.18,-14.95c4.04667,-3.6 9.57,-5.4 16.57,-5.4v0c7.06667,0 12.55,1.71 16.45,5.13c3.9,3.41333 5.85,8.23667 5.85,14.47v0c0,2.83333 -0.63333,5.51 -1.9,8.03c-1.26667,2.51333 -3.48333,5.30333 -6.65,8.37v0l-4.05,3.85c-2.53333,2.43333 -3.98333,5.28333 -4.35,8.55v0zM118.869,141.472v0c0,-2.23333 0.76,-4.07333 2.28,-5.52c1.51333,-1.45333 3.45333,-2.18 5.82,-2.18c2.36667,0 4.31,0.72667 5.83,2.18c1.51333,1.44667 2.27,3.28667 2.27,5.52v0c0,2.2 -0.74,4.01667 -2.22,5.45c-1.48667,1.43333 -3.44667,2.15 -5.88,2.15c-2.43333,0 -4.39,-0.71667 -5.87,-2.15c-1.48667,-1.43333 -2.23,-3.25 -2.23,-5.45z"></path>
		</g>
	</g>
</svg>
                

              </div>
             
            </div>
            
          </mgl-marker>
        </ng-template>
        
        <!-- End of Questions marker -->
        
      <!-- Enemy marker -->
      <div *ngFor="let enemyMarker of gameService.enemyMarkers">
        <mgl-marker class="text-center d-flex justify-content-center" *ngIf="enemyMarker && enemyMarker.lng && enemyMarker.lat && !enemyMarker.isFinished"  [lngLat]="[enemyMarker.lng, enemyMarker.lat]">
          <div>
            <div class="fi-map-marker-enemy bg-2">
              <img [src]="emojiService.getEmojiByIndex(enemyMarker.avatarIndex).icon" width="30" alt="">
            </div>
            <div class="flinkit-text-small fi-text-bold w-100 text-center">{{enemyMarker.name}}</div>
          </div>
       
        </mgl-marker>
      </div>
     
    
  

  
    
  </mgl-map>
  <div id="fi-map-header" class="shadow" *ngIf="gameService.me && gameService.me.name">
    <div class="row">
      <div class="col-2"></div>
      <div class="col-8">
        <div class="text-center text-truncate fi-text-bold">{{gameService.me.name}}</div>
      </div>
      <!--  <div class="col-3 text-center">
        <div class="fi-header-circle-btn shadow ml-auto mr-auto d-flex align-items-center justify-content-center">
          <img [src]="emojiService.getEmojiByIndex(me.avatarIndex).icon" width="35" alt="">
        </div>

        <div class="fi-header-circle-btn shadow ml-auto mr-auto d-flex align-items-center justify-content-center mt-3">
          <img [src]="serviceGuard.signalIcon" width="35" alt="">
        </div>
      </div> -->
    </div>
  </div>
  <!-- Map controls -->
  <div id="fi-map-control-container">

   <!--   <button class="fi-map-control shadow" [disabled]="pageStatus.maxZoom === pageStatus.zoom" [ngClass]="{'active': pageStatus.maxZoom === pageStatus.zoom}" (click)="zoom(true)"><i class="las la-plus"></i></button>
    <button class="fi-map-control shadow mt-1"  [disabled]="pageStatus.minZoom === pageStatus.zoom" [ngClass]="{'active': pageStatus.minZoom === pageStatus.zoom}" (click)="zoom(false)"><i class="las la-minus"></i></button>
    <button class="fi-map-control shadow mt-1" [ngClass]="{'active': !pageStatus.followMe}" (click)="toggleFollowMeMode()"><i class="las la-crosshairs"></i></button> -->
    <button class="fi-map-control d-flex align-items-center justify-content-center shadow mt-1" [style.background-color]="bgColor1" [style.border-color]="bgColor1"  data-toggle="tooltip" data-placement="left" title="Tooltip on right" *ngIf="gameService.activeGame.tourId.isQR && gameService.activeGame.partner.raster_image_url.length > 1" (click)="toggleLevels()">{{activeLevel}}</button> 
<!--     <button class="fi-map-control d-flex align-items-center justify-content-center shadow mt-1" [style.background-color]="bgColor1" [style.border-color]="bgColor1"   (click)="dropWalkthrough()"><i class="las la-question"></i></button> 
 -->    <button class="fi-map-control d-flex align-items-center justify-content-center shadow mt-1" [style.background-color]="bgColor1" [style.border-color]="bgColor1"  *ngIf="gameService.activeGame && gameService.activeGame.partner && !gameService.activeGame.partner.isMuseum" (click)="refresh()"><i class="las la-sync"></i></button> 
    <button class="fi-map-control d-flex align-items-center justify-content-center shadow mt-1" [style.background-color]="bgColor1" [style.border-color]="bgColor1"  *ngIf="!gameService.activeGame.tourId.isQR" (click)="locateMe()"><i class="las la-map-marker"></i></button> 
  </div>

  <!-- End of Map controls -->
  <!-- Divider -->
  <div class="position-absolute bottom-pos-0" style="margin-bottom: -1px; z-index: 999; width:100%;">
    <img src="./assets/images/divider.png" class="img-fluid w-100" alt="">
  </div>
  <!-- End of Divider -->
  <!-- Scroll Divider -->
  <button class="position-absolute map-refresh-btn qr flinkint-btn primary pl-5 pr-5 mt-1 animatebutton" style="z-index: 999;" (click)="scanQR()" *ngIf="this.gameService.activeGame.tourId.isQR" [class.naked]="gameService.activeGame.tourId.isNaked" >
    <span i18n>Scan QR code</span> 
  </button>

  <button class="position-absolute map-bomb-active flinkint-btn primary  mt-1" style="z-index: 999;" *ngIf="activePerks.bomb">
    <img width="30" alt="" src="./assets/images/v3/bomb.svg"><span>{{localeService.getTrans("Bomb perk active. Half points at next question.")}}</span>
  </button>

 <!--  <button class="position-absolute map-refresh-btn flinkint-btn primary pl-5 pr-5 mt-1 animatebutton" style="z-index: 999;" (click)="refresh()" [disabled]="!geoService.canLookup" *ngIf="gameService.activeGame && gameService.activeGame.partner && !gameService.activeGame.partner.isMuseum" >
    <span i18n>Refresh</span> 

  </button> -->

  <!-- End of Scroll Divider -->
</div>
<!-- End of Map container -->
<!-- Map container -->
<div id="fi-map-content-container" [class.h-100]="gameService.activeGame.tourId.isNaked"  *ngIf="gameService.me && gameService.activeGame">

  <!-- Main indicators -->
  <div class="row mt-3 mr-0 ml-0">
    <div class="text-center" [class]="gameService.activeGame.tourId.isGameTimer ? 'col-5 offset-1' : 'col-12'" >
      <h1 class="fi-text-bold fi-text-huge" [ngClass]="{'fi-text-huge-2' : (gameService.me.points) >= 1000}">{{gameService.me.points}}</h1>
      <h6 class="fi-text-medium fi-text-gray" i18n>points</h6> 
      
    </div>
    <div class="col-5 text-center" *ngIf="gameService.activeGame.tourId.isGameTimer">
      <h1 class="fi-text-bold fi-text-huge" [ngClass]="{'fi-text-huge-2' : (gameService.me.points) >= 1000}">{{pageConfig.remainingMinutes}}
      </h1>
      <h6 class="fi-text-medium fi-text-gray" i18n>mins</h6>
    <!--   <button (click)="mins()">Mins</button> -->
    </div>
  </div>
  <!-- End of Main indicators -->
  <div class="row mt-3" *ngIf="gameService.activeGame.tourId.isNaked">
    <div class="col-12 text-center">
      <div style="padding: 10px 15px; border-radius: 20px;" [style.background-color]="gameService.activeGame.tourId.isNaked ? bgColor1 : null" class="d-flex align-items-center text-center justify-content-center mx-2">
        <span class="fi-text-08"><strong>{{gameService.me.passedQuests.length}}</strong> kérdés megválaszolva, <strong>{{gameService.activeGame.quests.length}}</strong>  kérdést kell még megtalálni!</span>

      </div>

    </div>
  </div>
 
  <!-- AR / Normal game changer -->
  <!--<div class="row mt-3 mr-0 ml-0">
    <div class="col-12">
      <div class="fi-toggle-button d-inline-flex">
        <div class="flex-fill fi-text-bold" [ngClass]="{'active': pageStatus.mode === 'ar'}" (click)="changeMapMode('ar')">AR</div>
        <div class="flex-fill fi-text-bold" [ngClass]="{'active': pageStatus.mode === 'map'}" (click)="changeMapMode('map')">Térkép</div>
      </div>
    </div>
  </div>-->
  <!-- End of AR / Normal game changer -->
    <!-- Backpack -->
   <!--  <div id="backpacks" class="row mt-4 mr-0 ml-0" *ngIf="gameService.activeGame && gameService.activeGame.tourId.isStory && !gameService.isPartnerGame">
      <div class="col-12">
        <h5 class="fi-text-bold" i18n>Backpack</h5>
      </div>
       <div class="col-12">
        <swiper [config]="swiperConfig" #usefulSwiper class="mt-3" *ngIf="(gameService.me && gameService.me.backpack.length) else emptyBackpack">
          <div class="swiper-wrapper">
            <div class="swiper-slide" *ngFor="let item of gameService.me.backpack" (click)="clickedBackpack(item)">
              <div class="fi-game-card gray d-flex align-items-center justify-content-center">
                <div class="w-100 p-2">
                  <img [src]="item" class="img-fluid" alt="">
                </div>
              </div>
            </div>

          </div>
        </swiper>
        <ng-template #emptyBackpack>
          <div class="row">
            <div class="col-12 pt-5 pb-5 d-flex align-items-center justify-content-center">
              <div class="text-center">
                <img src="./assets/images/pois/backpack.png" width="120">
                <h5 class="fi-text-bold" i18n>Backpack is empty</h5>
              </div>
             
            </div>
          </div>
        </ng-template>
      </div>
    </div> -->
    <!-- End of Backpack -->
  <!-- Perks -->
  <!-- Input -->

<!--   <input type="file" id="camcorder" (change)="uploadImages($event)" style="display: none;" accept="image/*" capture="user">
 -->  <!-- End of Input -->
 <div class="row mt-4 mr-0 ml-0" *ngIf="gameService.activeGame && gameService.activeGame.perks.length > 0">
    <div class="col-12">
      <h5 class="fi-text-bold" i18n>Perks</h5>
    </div>
     <div class="col-12">
      <swiper [config]="swiperConfig" #usefulSwiper class="mt-3">
        <div class="swiper-wrapper">
          <div class="swiper-slide" *ngFor="let perk of gameService.activeGame.perks">
            <div class="fi-game-card d-flex align-items-center justify-content-center" [class]="gameService.me.points < perk.points * 1  ? 'disabled' : ''" (click)="togglePerk(gifModal,{type: perk.type, points: perk.points})">
              <div class="w-100 p-2">
                <div class="text-white fi-text-bold text-truncate text-capitalize">{{localeService.getTrans(perk.type)}}</div>
                <h1 class="mt-4 mb-4 fi-text-huge"><img [src]="'./assets/images/v3/'+perk.type+'.svg'" width="70" alt=""></h1>
                <h5 class="text-white fi-text-bold text-truncate" *ngIf="perk.points else freeBlock">{{perk.points}} <span i18n *ngIf="perk.points">points</span></h5>
                <ng-template #freeBlock><h5 class="text-white fi-text-bold text-truncate" i18n>Free</h5></ng-template>
              </div>
            </div>
          
          </div>
        
        </div>
      </swiper>
    </div>
  </div>
  <!-- End of Perks -->
  <!-- HOF -->
  <div class="row mt-4 mr-0 ml-0" *ngIf="gameService.activeGame && gameService.activeGame.players && gameService.activeGame.tourId.isLeaderboard">
    <div class="col-6">
      <h5 class="fi-text-bold text-truncate" i18n>Players</h5>
    </div>
    <div class="col-6 text-right">
     <!--   <h6 class="fi-text-bold text-truncate"> <span i18n>Your rank:</span> {{gameService.me.ranking+1}}.</h6> -->
  </div>
    <div class="col-12 table-responsive pr-0 pl-0 mt-3" *ngIf="!gameService.activeGame.isMuseum else museumSwiperBlock">
      <table class="table table-striped">
        <thead>
          <tr>
            <th scope="col" i18n>Rank</th>
            <th scope="col" i18n>Player</th>
            <th scope="col" i18n>Points</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let player of getRankingMapPlayers() trackBy: points; let i = index" (click)="scrollToContainer('fi-map-container', player)">
            <td class="align-middle">{{i+1}}.</td>
            <td class="align-middle" scope="row"><div class=" text-truncate"><img [src]="emojiService.getEmojiByIndex(player.avatarIndex).icon" class="mr-1 mb-1" width="20"
              alt="">{{player.name}}</div></td>
            <td class="align-middle"><div class="fi-text-bold text-truncate">{{player.points}} <span i18n>points</span></div></td>
          </tr>
          
        </tbody>
      </table>
    
    </div>
    <ng-template #museumSwiperBlock>
      <div class="col-12 pr-0 pl-0">
        <swiper [config]="{
      spaceBetween: 10,
      loop: false,
      slidesPerView: 'auto',
      initialSlide:1,
      loopedSlides: 2,
      centeredSlides: true
    }" #usefulSwiper class="mt-3">
          <div class="swiper-wrapper fi-players-swiper">
            <div class="swiper-slide" *ngFor="let player of museumPlayers trackBy: points;">
              <div class="fi-game-card bg-3 d-flex align-items-center justify-content-center" (click)="showPlayerInfo(player)" [style.background-color]="bgColor1">
                <div class="w-100 p-2">
                  <div class="text-white fi-text-bold text-truncate">{{player.name}}</div>
                  <h1 class="mt-4 mb-4"><img [src]="emojiService.getEmojiByIndex(player?.avatarIndex).icon" width="60"
                      alt=""></h1>
                  <h5 class="text-white fi-text-bold text-truncate mb-1" >{{player.points}} <span i18n>points</span></h5>
<!--                   <div class="d-flex align-items-center w-100 justify-content-center" *ngIf="!player.isFinished"><div class="inMuseum" i18n>Active</div></div>
 -->                </div>
              </div>
            </div>
          </div>
        </swiper>
      </div>
    </ng-template>
  </div>
  <div class="row mt-4 mr-0 ml-0" id="message_history" *ngIf="messageHistory && messageHistory.length">
    <div class="col-12">
      <h5 class="fi-text-bold text-truncate" i18n>Messages</h5>
    </div>
  
    <div class="col-12 table-responsive pr-0 pl-0 mt-3">
      <div class="fi-chat-panel">
        <div class="body">
          <div class="mine mb-3"  *ngFor="let message of messageHistory">
            <div class="message mb-0">
              <div class="text" >
                <p class="mb-0 pb-0">{{message.message.adminMessage}}</p>
              </div>
            </div>
            <div class="text-right">
              <small class="text-muted">{{message.time}}</small>
            </div>
         
          
          </div>
          
        </div>
      </div>
    
    </div>
 
  </div>
 
  <!-- End of HOF -->
  <!-- Game history -->
  <div class="container">
    <div class="row mt-4 mr-0 ml-0" id="messagesContainer">
      <div class="col-12 mt-3">
<!--         <button class="flinkint-btn primary btn-block mt-1" [style.background-color]="bgColor1" [style.border-color]="bgColor1" (click)="dropWalkthrough()" i18n>How it works?</button>
 -->        <button class="flinkint-btn danger btn-block mt-1" (click)="endGameDirectly()" i18n>End game</button>
      </div>
    </div>
    <div class="row text-center mt-3" *ngIf="!gameService.activeGame.partner.isMuseum && gameService.activeGame && gameService.activeGame.partner && gameService.activeGame.partner.isExclusiveBranded">
      <div class="col-12 text-center fi-text-bold">
        <img [src]="gameService.activeGame.tourId.isCustomBranding ? gameService.activeGame.tourId.customBranding.cover : ((gameService.activeGame.partner && gameService.activeGame.partner.customLogo) ? gameService.activeGame && gameService.activeGame.partner.customLogo : './assets/images/pbe.png')"  width="90"alt="">
      </div>
    </div>
  <!--   <div class="row text-center mt-3" *ngIf="gameService.activeGame.partner.isMuseum && gameService.activeGame.partner.isDigitalMuseum && gameService.activeGame && gameService.activeGame.partner && gameService.activeGame.partner.isExclusiveBranded">
      <div class="col-12 text-center fi-text-bold">
        <img [src]="gameService.activeGame.tourId.isCustomBranding ? gameService.activeGame.tourId.customBranding.cover : ((gameService.activeGame.partner && gameService.activeGame.partner.logo) ? gameService.activeGame && gameService.activeGame.partner.logo : './assets/images/pbe.png')"  width="90"alt="">
      </div>
    </div>
    <div class="row text-center mt-3" *ngIf="gameService.activeGame.partner.isMuseum && !gameService.activeGame.partner.isDigitalMuseum ">
      <div class="col-12 text-center fi-text-bold">
        <img src="./assets/images/pbe.png"  width="90"alt="">
      </div>
    </div> -->
  </div> 
 
  <!-- End of Game history -->
</div>
<!-- End of Map container -->

<!-- Freeze modal -->
<div id="fl-freeze-modal" class="text-center" *ngIf="gameService.interimSettings.freezeActive">
  <div>
    <h1 class="mb-4 fi-text-huge"><img src="./assets/images/emoji/ice.png" width="70" alt=""></h1>
    <h5 class="fi-text-bold"><span>{{localeService.getTrans("Somebody froze all the players for 30 seconds.")}}</span></h5>
  </div>
</div>
<!-- End of Freeze modal -->
<!-- QR Modal -->
<div id="qr_overlay" *ngIf="pageConfig.showQrReader">
  <div class="close shadow" (click)="closeQR()">X</div>
  <zxing-scanner #scanner start="true" [device]="currentDevice" (scanSuccess)="handleQrCodeResult($event)" [formats]="['QR_CODE']"></zxing-scanner>
</div>
<!-- End of QR Modal -->
<!-- GIF modal -->
<ng-template #gifModal let-modal>
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title" i18n>Choose a gif</h4>
  </div>
  <div class="modal-body">
    <form>
      <div class="mb-3">
          <div class="row">
            <div class="col-6" *ngFor="let gif of availableGifs">
              <img [src]="gif.url" width="200" height="200" alt="">
            </div>
          </div>
      </div>
    </form>
  </div>
</ng-template>

<ng-template #textInput let-modal>
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title" i18n>{{localeService.getTrans('Send message to Game host')}}</h4>
  </div>
  <div class="modal-body">
    <form>
      <div class="mb-3">
        <div class="row">
          <div class="col-12" >
            <!--  -->
            <textarea type="text" class="form-control" [ngModelOptions]="{standalone: true}"   [(ngModel)]="textInputValue" ></textarea>
            <button type="button" class="flinkint-btn success btn-block mt-3" (click)="sendTextInput()">{{localeService.getTrans('Send')}}</button>
          </div>
        </div>
      </div>
    </form>
  </div>
</ng-template>


<ng-template #camCorderModal let-modal>
  <div class="modal-header">
    <h4 class="modal-title" i18n>
      {{localeService.getTrans('Take a photo')}}
    </h4>
  </div>
  <div class="modal-body">
      <div class="mb-3">
          <div class="row">
            <div class="col-12">
                <div id="previewContainer" class="text-center">
                  <img [src]="dynamic_url" class="img-fluid" id="dynamic_img" *ngIf="dynamic_url else imgPlaceHolder" />
                  <ng-template #imgPlaceHolder>
                    <img src="./assets/images/v3/photo.svg" alt="">
                    <h6 class="text-muted">
                      {{localeService.getTrans('No image selected')}}
                    </h6>
                  </ng-template>
                </div>
            </div>
          </div>
          <div class="row">
            <div class="col-12">
              <label for="camcorder" class="flinkint-btn primary btn-block mt-1">
                {{localeService.getTrans('Take a photo')}}
            </label>
              <input type="file" id="camcorder" style="display: none;" (change)="uploadImages($event)" accept="image/*" capture="user">
              <button type="button" class="flinkint-btn success btn-block mt-1" *ngIf="previewImage" [disabled]="!previewImage" (click)="uploadImage()">
                {{localeService.getTrans('Upload image')}}
              </button>
            </div>
          </div>
      </div>
  </div>
</ng-template>

<!-- Global payment modal -->
<div id="globalPaymentModal" *ngIf="geoService.isPaymentActive">
  <i class="las la-circle-notch la-2x la-spin"></i>
</div>
<!-- End of Global payment modal -->