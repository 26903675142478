
import { Component, Input, OnInit } from '@angular/core';
import * as _ from 'lodash';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { GameService } from 'src/app/services/game.service';
import { LocaleService } from 'src/app/services/locale.service';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { DomSanitizer } from '@angular/platform-browser';
@Component({
  selector: 'app-questionModal',
  templateUrl: './questionModal.component.html',
  styleUrls: ['./questionModal.component.scss']
})
export class QuestionModalComponent implements OnInit {

  @Input() question: any;
  @Input() isDoubleActive: boolean;
  modalState = 'standby';
  passedTime = 1;
  questionCountdown;
  endPoiAnswer = ['0','0','0']
  selectedAnswer: any;
  loading = false;
  constructor(public activeModal: NgbActiveModal, private sanitizer: DomSanitizer, public gameService: GameService, public localeService: LocaleService, private http: HttpClient) {
    
  }
  proceedToQuestion(){
    if(this.modalState === 'endPoi'){
      this.modalState = 'endPoiQuestion';
    } else if(this.modalState === 'standby' || this.modalState === 'timelineEndPoi'){
      // TODO itt lesz a hiba
      this.modalState = 'question';
      if(!this.question.mediaUrl){
        this.startCountdown();
      }
    } else{
      this.modalState = 'infoQuestion';
    }
   
  }

  validateAnswer(){
    
    if(this.modalState === 'endPoiQuestion'){
        let pins = this.endPoiAnswer[0]+this.endPoiAnswer[1]+this.endPoiAnswer[2];
        console.log(pins.toString())
        if(pins.toString() === this.question.answer.toString()){
          this.activeModal.close({type: 'endPoiSucess' });
        } else{
          this.activeModal.close({type: 'endPoiNotSucess' });
        }
    } else{
      if(this.question.questionType === 'options' || !this.question.questionType){
        if(this.selectedAnswer.isTrue){
          this.correctAnswer(this.selectedAnswer.answer);
          if (this.selectedAnswer.consequencePOI) {
            this.modalState = 'consequencePOI'
          }
        } else{
          this.incorrectAnswer(this.selectedAnswer.answer);
        }
      } else if(this.question.questionType === 'text'){
        const qtAnswer = this.question.answers[0].answer.toString()
        if(this.selectedAnswer){
          if(this.selectedAnswer.toLowerCase().trim() == qtAnswer.toLowerCase().trim()){
            this.correctAnswer(this.selectedAnswer);
          } else{
            this.incorrectAnswer(this.selectedAnswer.answer);
          }
        } else{
          this.incorrectAnswer(this.selectedAnswer.answer);
        }
       
      } else if(this.question.questionType === '2'){
        const upperCap = this.question.answers[0].answer + this.question.answers[0].interval;
        const lowerCap = this.question.answers[0].answer - this.question.answers[0].interval;
        if(this.selectedAnswer > lowerCap && this.selectedAnswer <= upperCap){
          this.correctAnswer(this.selectedAnswer.answer);
        } else{
          this.incorrectAnswer(this.selectedAnswer.answer);
        }
      } else if(this.question.questionType === 'ai'){
        this.loading = true;
        this.http
        .post(environment.baseUrl + 'questions/testAIQuestions', {
          prompt: this.question.answerPrompt,
          answer: this.selectedAnswer
        })
        .subscribe((data: any) => {
          this.loading = false;
          if(data.correct){
            this.correctAnswer(this.selectedAnswer.answer);
          } else{
            this.incorrectAnswer(this.selectedAnswer.answer);
          }
        });
      }
    }
  }

  navigateToUrl(url: string) {
    window.location.href = url
  }

  getFormattedQuestion(string: string) {
    // Extract <a> tag href value
    const linkMatch = string.match(/<a[^>]*href="([^"]*)"/);
    const extractedUrl = linkMatch ? linkMatch[1] : null;
  
    if (extractedUrl) {
      // If there's an href in the anchor tag, replace the <a> with its inner text
      const linkText = string.replace(/<a[^>]*>(.*?)<\/a>/, '$1');
      // Pass the URL to the navigateToUrl method when clicking the button
      return {
        html: linkText, // Only text content to show inside the button
        url: extractedUrl
      };
    }
  
    // Return the formatted string if no link is found
    return { html: string, url: null };
  }
  

  closeModalWithInfo(){
    this.activeModal.close({type: 'closeWithInfo'});
  }

  closeModal(){
    this.activeModal.close({type: 'backpack', data: this.question });
  }

  closeModalWithoutEvent(){
    this.activeModal.close({type: 'closeWithoutEvent'});
  }

  correctAnswer(answer){
    this.activeModal.close({type: 'correct', data: this.question, answer, time: this.passedTime });

  }
  incorrectAnswer(answer){
    this.activeModal.close({type: 'incorrect', data: this.question, answer, time: this.passedTime});
  }
  toggleAnswer(answer: any){
    this.selectedAnswer = answer;
  }
  startCountdown(){
     this.questionCountdown = setInterval(()=>{
       if(this.passedTime < this.question.time){
         this.passedTime += 1;
       } else{
          this.activeModal.close({type: 'outOfTime', data: this.question, answer: '', time: this.passedTime});
         clearInterval(this.questionCountdown); 
       }
     }, 1000);
  }
  imageLoaded(){
    if(this.question.isQuestion) {
       this.startCountdown();
    }
  }
  ngOnInit() {
    if(this.question.isQuestion){
      if(this.question.isEndPOITimeline){
        this.modalState = 'timelineEndPoi';
      } else{
        if(this.question.questionType === 'story'){
          this.modalState = 'story';
        } else if(this.question.questionType === 'endPoi'){
          this.modalState = 'endPoi';
        }
      }
     
    } else{
      this.modalState = 'info';
    }
   
  }

}
