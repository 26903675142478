import { HttpClient } from '@angular/common/http';
import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { SwiperComponent } from 'ngx-useful-swiper';
import { environment } from '../../environments/environment';
import { LocaleService } from '../services/locale.service';
import { MobileAppService } from '../services/mobile-app.service';

@Component({
  selector: 'app-museum-walkthrough',
  templateUrl: './museum-walkthrough.component.html',
  styleUrls: ['./museum-walkthrough.component.scss']
})
export class MuseumWalkthroughComponent implements OnInit {
  @ViewChild('usefulSwiper',{static: true}) usefulSwiper: SwiperComponent;
  partner;
  game;
  bgColor;
  swiperConfig = {
    loop: false,
    slidesPerView: 'auto',
    centeredSlides: true,
    autoplay: {
      delay: 4000,
    },
    pagination: {
      el: '.swiper-pagination',
      type: 'bullets',
    },
  };
  locales = {
   
    walkthrough: this.localeService.getTrans("Flinkit is a new real world adventure game packed with codes to be cracked, clues to be uncovered, and puzzles to be solved in and outside. Fast, fun and easy to get going."),
    questionsAndMarkers: this.localeService.getTrans("You can unlock questions by approaching them. The points are triggered when you stand in a 10 meters radius."),
    questionsAndMarkers2: this.localeService.getTrans("You can unlock questions by finding QR codes in the museum (marked on the map) and scanning them with the 'Scan QR Code' button."),
    walkthroughText2: this.localeService.getTrans("The goal of the game is collect as many points as you can while solve riddles on the map."),
  }
  constructor(private route: ActivatedRoute, private router: Router, private http: HttpClient, private mobileAppService: MobileAppService, private localeService: LocaleService) {
    this.detectLocale();
  }

  joinGame(){
    if(!this.game.tourId.isStartScreen){
      this.http.post(environment.baseUrl + 'games/addStartedDate', { gameId: this.route.snapshot.paramMap.get('g') }).subscribe((data: any) => {
        if (data.success) {
          this.router.navigate(['/game', {g: this.route.snapshot.paramMap.get('g'), p: this.route.snapshot.paramMap.get('p'), showcase: this.route.snapshot.paramMap.get('showcase') }]);
        } else {
          console.log('started date not added');
        }
      });
    } else{
      this.router.navigate(['/start-screen', {g: this.route.snapshot.paramMap.get('g'), p: this.route.snapshot.paramMap.get('p'), showcase: this.route.snapshot.paramMap.get('showcase') }]);
    }
  }

  getGame(gameId, player){
    return this.http.post(environment.baseUrl + 'games/getGame', {gameId, player} ).subscribe((data: any) => {
     if(data.success){
       this.game = data.data;
       this.bgColor = this.game.partner ? ((this.game.tourId.isCustomBranding && this.game.tourId.customBranding.color) ? this.game.tourId.customBranding.color : (this.game.partner.color ? this.game.partner.color : null)) : null;

      }
   }); 
 }

  getPartnerDetails(_id){
    return this.http
    .post(
      environment.baseUrl + 'partners/getPartner',
      {
        _id
      }
    )
    .subscribe((data: any) => {
      console.log(data);
      if(data.success){
        this.partner = data.data;
      } 
    });
  }
  ngOnInit() {
    if (!this.route.snapshot.paramMap.get('g') || !this.route.snapshot.paramMap.get('p') || !this.route.snapshot.paramMap.get('partner')) {
      if(this.route.snapshot.paramMap.get('partner')){
        window.location.href = this.mobileAppService.getURL()+"en/museum-landing?uid=" + this.route.snapshot.paramMap.get('partner');
      } else{
        this.router.navigate(['/museum-landing']);
      }
    } else{
      this.getPartnerDetails(this.route.snapshot.paramMap.get('partner'));
      this.getGame(this.route.snapshot.paramMap.get('g'),this.route.snapshot.paramMap.get('p'))
    } 
  }

  detectLocale(){
    if(window.location.href.indexOf('/hu/') !== -1){
      this.localeService.locale = 'hu';
    } else if(window.location.href.indexOf('/de/') !== -1){
      this.localeService.locale = 'de';
    } else if(window.location.href.indexOf('/nl/') !== -1){
      this.localeService.locale = 'nl';
    } else if(window.location.href.indexOf('/fr/') !== -1){
      this.localeService.locale = 'fr';
    }  else{
      this.localeService.locale = 'en';
    }


    this.locales = {
      walkthrough: this.localeService.getTrans("Flinkit is a new real world adventure game packed with codes to be cracked, clues to be uncovered, and puzzles to be solved in and outside. Fast, fun and easy to get going."),
      questionsAndMarkers: this.localeService.getTrans("You can unlock questions by approaching them. The points are triggered when you stand in a 10 meters radius."),
      questionsAndMarkers2: this.localeService.getTrans("You can unlock questions by finding QR codes in the museum (marked on the map) and scanning them with the 'Scan QR Code' button."),
      walkthroughText2: this.localeService.getTrans("The goal of the game is collect as many points as you can while solve riddles on the map."),
    }
  }


}
