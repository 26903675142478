<!-- End game -->
<div
  class="flinkit-full-bg overflow-auto pt-3 pb-5"
  style="
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: column;
  "
>
  <div class="row mr-0 ml-0">
    <div class="col-12 d-flex align-items-center justify-content-center h-100">
        <div class="text-center flex-fill justify-content-between">
          <div class="row">
            <div class="col-12">
              <div class="row text-center" *ngIf="game && game.partner && !game.partner.isMuseum && game.partner.isWhiteLabel">
                <div class="col-12 text-center fi-text-bold">
                  <img [src]="game.tourId.isCustomBranding ? game.tourId.customBranding.cover : ((game.partner && game.partner.customLogo) ? game && game.partner.customLogo : './assets/images/logo_bp.png')"  width="120"alt="">
                </div>
              </div>
              <h3 class="fi-text-bold text-white mt-5">
                {{locales.opinion}}
              </h3>
              <div
                class="d-flex mt-5"
                style="gap: 10px; justify-content: center"
              >
                <img
                  (click)="setRating(1)"
                  [src]="
                    rating > 0
                      ? './assets/images/star.svg'
                      : './assets/images/gray-star.svg'
                  "
                  width="40"
                />
                <img
                  (click)="setRating(2)"
                  [src]="
                    rating > 1
                      ? './assets/images/star.svg'
                      : './assets/images/gray-star.svg'
                  "
                  width="40"
                />
                <img
                  (click)="setRating(3)"
                  [src]="
                    rating > 2
                      ? './assets/images/star.svg'
                      : './assets/images/gray-star.svg'
                  "
                  width="40"
                />
                <img
                  (click)="setRating(4)"
                  [src]="
                    rating > 3
                      ? './assets/images/star.svg'
                      : './assets/images/gray-star.svg'
                  "
                  width="40"
                />
                <img
                  (click)="setRating(5)"
                  [src]="
                    rating > 4
                      ? './assets/images/star.svg'
                      : './assets/images/gray-star.svg'
                  "
                  width="40"
                />
              </div>
              <textarea
                [placeholder]="locales.placeholder"
                class="mt-5 p-2"
                [(ngModel)]="comment"
                cols="30"
                rows="4"
              ></textarea>
            </div>
          </div>
        </div>
      </div>
      <button class="flinkint-btn white btn-block mt-3 pt-3 pb-3 mx-3" (click)="sendReview()" [disabled]="alreadyReviewed">{{locales.next}}</button>
      <a class="text-white text-center w-100 mt-3 mb-3" (click)="navigateToLeaderboard()" i18n>{{locales.skip}}</a>

  </div>
  
</div>
<!-- End of End game -->
