import { NgModule } from "@angular/core";
import { ReviewComponent } from "./review.component";
import { CommonModule } from "@angular/common";
import { FormsModule } from "@angular/forms";
import { HttpClientModule } from "@angular/common/http";

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        HttpClientModule
    ],
    exports: [ReviewComponent],
    declarations: [ReviewComponent],
})
export class ReviewModule { }