import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { environment } from '../../environments/environment';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'app-end-screen',
  templateUrl: './end-screen.component.html',
  styleUrls: ['./end-screen.component.scss']
})
export class EndScreenComponent implements OnInit {
  game;
  bgColor;
  constructor(private route: ActivatedRoute, private sanitizer: DomSanitizer, private router: Router, private http: HttpClient) { }

  navigateBack() {
    this.router.navigate(['/']);
  }

  getGame(gameId, player){
    return this.http.post(environment.baseUrl + 'games/getGame', {gameId, player} ).subscribe((data: any) => {
     if(data.success){
       this.game = data.data;
       this.bgColor = this.game.partner ? ((this.game.tourId.isCustomBranding && this.game.tourId.customBranding.color) ? this.game.tourId.customBranding.color : (this.game.partner.color ? this.game.partner.color : null)) : null;

       console.log('am', this.game)
     }
   }); 
 }

 getFormattedText(string){
  return this.sanitizer.bypassSecurityTrustHtml(string);
}

 jumpToResults(){
    if (this.game.isMuseum) {
    this.router.navigate(['/museum-leaderboard', { g: this.route.snapshot.paramMap.get('g'), p: this.route.snapshot.paramMap.get('p') , groupId: this.route.snapshot.paramMap.get('groupId') }]);
    } else {

      console.log('this.game.tourId.isReview', this.game.tourId.isReview)

       if (this.game.tourId.isReview) {
        this.router.navigate(['/review', { g: this.route.snapshot.paramMap.get('g'), p: this.route.snapshot.paramMap.get('p') , groupId: this.route.snapshot.paramMap.get('groupId') }]);
      } else{
        this.router.navigate(['/endGame', { g: this.route.snapshot.paramMap.get('g'), p: this.route.snapshot.paramMap.get('p') , groupId: this.route.snapshot.paramMap.get('groupId') }]);

      }
    }

 }

  ngOnInit(): void {
    if (!this.route.snapshot.paramMap.get('g') || !this.route.snapshot.paramMap.get('p')) {
      this.navigateBack();
    } 
    if(this.route.snapshot.paramMap.get('g') && this.route.snapshot.paramMap.get('p')){
      this.getGame(this.route.snapshot.paramMap.get('g'), this.route.snapshot.paramMap.get('p'))
    }
   
  }

}
