<div class="jumbotron fl-main-cover" *ngIf="activeTour"  [ngStyle]="{'background':'url('+activeTour.cover_big+')', 'background-size':'cover'}">
  <div class="row">
    <div class="col-12  text-center d-flex text-left align-items-center">
      <div>
        <h3 class="fi-text-bold text-white mb-0">{{activeTour.name}}</h3>
        <div>
          <div class="fi-text-medium text-white">{{activeTour.length}} <span i18n>minutes</span> · {{activeTour.quests.length}} <span i18n>questions</span> · {{activeTour.difficulty}} </div>
        </div>
        <p class="text-white mt-3">{{activeTour.description}}</p>
      </div>
    </div>
  </div>
  <div class="row mt-3">
    <div class="col-12 text-center d-flex justify-content-center align-items-center">
      <button class="flinkint-btn white text-dark pr-5 pl-5" (click)="scrollTo('players')" i18n>Set players</button>
    </div>
  </div>
</div>

<div class="conatiner p-3" *ngIf="activeTour" id="players">
  <div class="row">
    <div class="col-12">
      <h4 class="fi-text-bold mb-0" i18n>Number of players</h4>
    </div>
  </div>
  <div class="row mt-3">
    <div class="col-12">
      <div class="form-group"  data-aos="fade-left" data-aos-delay="200">
        <ngx-slider [(value)]="checkoutObj.players" [options]="sliderOptions"></ngx-slider>
        <div class="flinkit-text-small d-flex text-center align-items-center justify-content-center"><i class="las la-info-circle"></i> <span i18n>The number of devices you can play the game with</span></div>
      </div>
    </div>
  </div>
</div>

 <div class="conatiner p-3" *ngIf="gameService.isPartnerGame">
  <div class="row">
    <div class="col-12">
      <h4 class="fi-text-bold mb-0" i18n>Mode</h4>
    </div>
  </div>
  <div class="row mt-3">
    <div class="col-12">
      <div class="fi-segmented-control gray">
        <button class="fi-text-bold" (click)="setMode(false)" [class]="checkoutObj.indoorMode === false ? 'active' : ''" i18n>Outdoor</button>
        <button class="fi-text-bold" (click)="setMode(true)"  [class]="checkoutObj.indoorMode === true ? 'active' : ''" i18n>Indoor</button>
      </div> 
    </div>
  </div>
</div>


<div class="conatiner p-3" >
  <div class="row">
    <div class="col-12">
      <h4 class="fi-text-bold mb-0" i18n>How it works?</h4>
    </div>
  </div>
  <div class="row mt-3">
    <div class="col-12">
     <app-walkthrough [swiperContent]="swiperContent"></app-walkthrough>
    </div>
  </div>
</div>

<div class="container pt-3 pl-3 pr-3 pb-5" >
  <div class="row">
    <div class="col-12">
      <h4 class="fi-text-bold mb-0" i18n>Game start</h4>
    </div>
  </div>
  <div class="row mt-3">
    <div class="col-12 position-relative" *ngIf="checkoutObj && checkoutObj.position else posLoadingBlock">
      <mgl-map [style]="'mapbox://styles/ercseypeter1/ckksgqro810vr17ord4zmvbum'" [interactive]="false" [minZoom]="12" [maxZoom]="16" [zoom]="[14]"
    [center]="this.pageConfig.center">
    <!-- Me marker -->
    <mgl-marker [draggable]="false"  [lngLat]="[checkoutObj.position.coords.longitude, checkoutObj.position.coords.latitude]">
      <div class="fi-map-marker-me">
      </div>
    </mgl-marker>
    <!-- End of Me marker -->
    </mgl-map>
    <button id="refreshBtn" class="flinkint-btn white text-dark shadow" (click)="setPosition()">
      <span *ngIf="!loaders.getLocation else loaderBlock" i18n>It's not where you want to start?</span>
      <ng-template #loaderBlock><i class="las la-spinner la-spin"></i></ng-template>
    </button>
    </div>
    <ng-template #posLoadingBlock>
        <div class="d-flex w-100 align-items-center justify-content-center p-5">
          <div class="text-center" *ngIf="(!pageConfig.locationError && loaders.getLocation) else locErrorBlock">
            <i class="las la-circle-notch la-2x la-spin"></i>
            <h5 i18n>Getting your position</h5>
          </div>
        </div>
    </ng-template>
    <ng-template #locErrorBlock>
      <div class="d-flex w-100 align-items-center justify-content-center">
        <div class="text-center">
         <img src="./assets/images/walkthrough/6a.png" width="120" alt="">
          <h5 class="mt-2 fi-text-bold">{{pageConfig.locationError}}</h5>
          <h6><i class="las la-info-circle mr-2"></i><span i18n>Please enable geolocation to play the game</span></h6>
          <button class="flinkint-btn white text-dark pr-5 pl-5 shadow mt-2" (click)="refreshPosition()" i18n>Refresh</button>
        </div>
      </div>
    </ng-template>
  </div>
</div>
<!-- Normal game start -->
<button *ngIf="activeTour && !activeTour.partner" id="checkoutBtn" [disabled]="!checkoutObj || !checkoutObj.position || loaders.startGame" class="flinkint-btn primary shadow btn-block" (click)="checkout()">
  <span *ngIf="checkoutObj.players < pageConfig.maxPlayers else contactBlock">
    <span *ngIf="!loaders.startGame else loaderBtnBlock"><span i18n>Checkout</span> (€{{checkoutObj.players*activeTour.price}})</span>
    <ng-template #loaderBtnBlock><i class="las la-spinner la-spin"></i></ng-template>
  </span>
  <ng-template #contactBlock>Contact us</ng-template>
</button>
<!-- End of Normal game start -->
<!-- Partner game start -->
<button *ngIf="activeTour && activeTour.partner" id="checkoutBtn" [disabled]="!checkoutObj || (!checkoutObj.position && !checkoutObj.indoorMode) || loaders.startGame" class="flinkint-btn primary shadow btn-block" (click)="checkout()">
  <span *ngIf="checkoutObj.players < pageConfig.maxPlayers else contactBlock">
    <span *ngIf="!loaders.startGame else loaderBtnBlock" i18n>Start game</span>
    <ng-template #loaderBtnBlock><i class="las la-spinner la-spin"></i></ng-template>
  </span>
  <ng-template #contactBlock i18n>Contact us</ng-template>
</button>
<!-- End of Partner game start -->
