<div class="d-md-flex align-items-center justify-content-center position-relative pt-7-rex pb-5-rex"
    style="background:url(./assets/images/welcome.png); background-size: cover;">
    <a class="fl-logo-absolute" href="https://flinkit.io"><img src="./assets/images/logo_onboarding_w.png" alt=""></a>

    <div class="fl-desktop-container position-relative d-none d-md-block">
        <div class="inner shadow p-4 position-relative">

            <a href="https://www.producthunt.com/posts/flinkit-friends?utm_source=badge-top-post-badge&utm_medium=badge&utm_souce=badge-flinkit-friends"
                target="_blank"><img
                    src="https://api.producthunt.com/widgets/embed-image/v1/top-post-badge.svg?post_id=291287&theme=light&period=daily"
                    alt="Flinkit Friends - Story-driven outdoor adventure games for teams, and friends | Product Hunt"
                    style="width: 250px; height: 54px;" width="250" height="54" /></a>
            <h2 class="fi-text-bold mt-3" ><span i18n>Make onboarding super fun!</span> <br> <span i18n> For all kind of company sizes.</span>
            </h2>


            <div class="fi-text-bold mt-3"> <span i18n>Make the first day of work exciting, fun and efficient for the new employee</span>
            </div>

            <!--       <img src="./assets/images/watch.png" data-toggle="modal" data-target="#videoModal" class="watch-icon fl-hoverer" width="40" alt="">
 --> <img src="./assets/images/telo.png" class="phone-icon-3" alt="">
        </div>
        <div class="outer"></div>
        <div class="content">
            <h5 class="fi-text-bold mb-1" i18n>Get in touch</h5>
            <div class="d-flex fi-desktop-footer justify-content-center w-100 mb-3">
                <div class="mr-2"><a href="mailto:info@flinkit.io">info@flinkit.io</a></div>
                <!-- <div class="ml-2"><a href="https://calendly.com/flinkitwebinar/30min?month=2021-03" target="_blank">Book a call with us</a></div> -->
            </div>
            <div class="fi-desktop-footer">
                <a href="https://calendly.com/flinkitwebinar/30min?month=2021-03"><span i18n>Do you have special needs?</span> <br> <span i18n>Book a call with us</span></a>
            </div>

        </div>
    </div>
    <div class="d-md-none d-block fl-mobile-landing-header w-100 p-3">
        <h4 class="fi-text-bold fi-text-vivid mb-0"><span>Make onboarding super fun!</span>
            <br><span> For all kind of company sizes.</span></h4>


    </div>
</div>
<div class="container-fluid pt-3 pt-md-5 fl-landing-gradient">
    <div class="row pt-0 pt-md-5">
        <div class="col-md-8 offset-md-2 text-center">
            <h2 class="fi-text-bold mt-3" i18n>A good first impression can work wonders</h2>
            <p><span i18n>Onboarding, the effective integration of new employees into the organization, is a process of great importance, as it is in the interest of all employers that new employees find their place quickly, get to know the organizational culture, expectations, appropriate behaviors, engage in work processes and have a good working relationship. with their colleagues.</span><span i18n>Make the first day of work exciting, fun and efficient for the new employee, Flinkit Onboarding! offers innovative solutions with unique content for effective and immersive knowledge transfer.</span><br><br> 
                <br>
                <span i18n>Flinkit Onboarding! solutions use the one of a kind “Learning in motion(R)” experience: An adventurous challenge for the new employee and a clear message from the employer promoting physical activity and a healthy lifestyle.</span>
            </p>
        </div>
    </div>
</div>

<div class="container pt-md-5">
    <div class="row d-flex align-items-center">
        <div class="col-md-6 pl-0 pr-0 d-flex justify-content-center">
            <img src="./assets/images/ek1.png" width="330"  alt="">
        </div>
        <div class="col-md-6 pl-md-0 pr-md-0">
            <h3 class="fi-text-bold mt-3" i18n>Benefits</h3>
            <ul class="mt-3">
                <li i18n>More effective learning process </li>
                <li i18n>Mobile-based technology is attractive to Z generation employees, - thus builds the employer brand</li>
                <li i18n>It runs on the employee's device, it can be used at any time, in any environment, indoors and outdoors</li>
                <li i18n>The subject material is understood better, and will be remembered.</li>
                <li i18n>Easy to use: does not require a trainer or application download</li>
                <li i18n>You can easily edit the material and questions so that the content is always up-to-date and flexible. (We are happy to help with this - we will be there with you throughout the process.)</li>
            </ul>

        </div>
    </div>
</div>

<div class="container pt-md-5">
    <div class="row d-flex align-items-center">

        <div class="col-md-6">
            <h3 class="fw-bold fi-text-bold mt-3" i18n>How it works?</h3>
            <p>We place the contents, information and questions on a map  available on a mobile phone, so your onboarding program can be transformed into an exciting, interactive virtual experience, even a game or a competition if need be.
                The knowledge transfer works both indoors and outdoors. The question or information ipops up on the device when the player walks to that point.</p>
                <br>
                Flinkit! is tailored to your needs:
                <ul class="text-bold-xs">
                <li i18n>can be played inside the office building or in the surrounding streets.</li>
                <li i18n>it can be used to walk around the factory building, the shopping floor, etc.</li>
                <li i18n>Mobile first solution running in all web browsers for easy use, access and fast integration</li>

            </ul>

        </div>
        <div class="col-md-6 d-flex justify-content-center">
            <img src="./assets/images/ek2.png" width="370" alt="">
        </div>
    </div>
</div>

<div class="container-fluid mt-5">
    <div class="row">
        <div class="col-12 pl-0 pr-0">
            <div class="pattern-bg first">

                <img src="./assets/images/ver2/pattern-ext.png" class="patter-ext" alt="">
                <div class="row">
                    <div class="col-12 col-md-10 offset-md-1 text-center">
                       
                    </div>
                </div>
                <div class="row mt-5">
                    <div class="col-md-6 col-12 d-flex fl-landing-image">
                        <img src="./assets/images/ver2/team-circle.png" class="mr-4 mobile-img" alt="">
                    </div>
                    <div class="col-md-5 col-12 mt-3 mt-md-0">
                       
                        <h2 class="fi-text-bold" i18n>What to expect?</h2>
                        <br>
                        <p i18n>Say, Anna is a new employee in your company, ready for her orientation experience. She launches Flinkit Onboarding! on her own mobile, and the map of your offce building or factory pops up. Beautiful red POI markers help her to navigate to every important location within the office, and when she arrives there, the pre-set information or question appears on her screen. She reads the information and quickly picks the right answer, - and she is on her way to the next point already, with smile on her face and with the solid determination to win the Flinkit Onboarding! race.
                           <br><br> She can do it alone or compete with other new hires. Once ready, you will see all her results, answers, and all fancy statistics, if you are into it, while Anna will tell about the fun she had right on her first day with your company to all her friends and colleagues, -maybe even bragging about her results. 
                            <br>
                            Isn’t that how you would want to start with your new company too?</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="container-fluid">
    <div class="row">
        <div class="col-12 pl-0 pr-0">
            <div class="pattern-bg plain">
                <img src="./assets/images/ver2/pattern-ext1.png" class="patter-ext green" alt="">
                <div class="row">
                    <div class="col-12 text-center">
                        <h2 class="fi-text-bold" i18n>Get it now</h2>
                    </div>
                </div>
                <div class="row mt-5">
                    <div class="col-md-4 offset-md-2 text-center d-flex justify-content-center">
                        <div>
                            <img src="./assets/images/ver2/icon2.png" class="max-w160" alt="">
                            <h5 class="mt-3 fi-text-bold" i18n>Admin panel</h5>
                            <p i18n>Using the administration interface provided by Flinkit, you can: create, modify, view game statistics, check and export player data for future use, or remarketing purposes.

                            </p>

                        </div>
                    </div>
                    <div class="col-md-4 text-center d-flex justify-content-center">
                        <div>
                            <img src="./assets/images/ver2/icon.png" class="max-w160" alt="">
                            <h5 class="mt-3 fi-text-bold" i18n>Easy integration</h5>
                            <p i18n>The integration requirements of the game are very low. The game can be used instantly after setting up the partner's dashboard.
                            </p>

                        </div>
                    </div>
                </div>
                <!--  <div class="row mt-5">
          <div class="col-md-4 offset-md-2 text-center d-flex justify-content-center">
            <div>
              <img src="./assets/images/ver2/icon6.png" class="max-w160" alt="">
              <h5 class="mt-3 fi-text-bold">1. lépés</h5>
              <p>Hozd létre saját Flinkit! fiókodat, állítsd össze a max. 4 fős csapatokat manuálisan, vagy a Flinkit! segítségével.
              </p>
              <a href="">Többet szeretnék tudni...</a>
            </div>
          </div>
          <div class="col-md-4 text-center d-flex justify-content-center">
            <div>
              <img src="./assets/images/ver2/icon7.png" class="max-w160" alt="">
              <h5 class="mt-3 fi-text-bold">1. lépés</h5>
              <p>Hozd létre saját Flinkit! fiókodat, állítsd össze a max. 4 fős csapatokat manuálisan, vagy a Flinkit! segítségével.
              </p>
              <a href="">Többet szeretnék tudni...</a>
            </div>
          </div>
        </div> -->
            </div>
        </div>
    </div>
</div>
<div class="container-fluid">
    <div class="row">
        <div class="col-12 pl-0 pr-0">
            <div class="pattern-bg plain2">
                <img src="./assets/images/ver2/footer.png" class="patter-ext green" alt="">
                <div class="row">
                    <div class="col-12 text-center">
                        <img src="./assets/images/ver2/slogo4.png" width="120" alt="">
                    </div>
                    <div class="col-12 text-center mt-3">
                        <a href="https://www.youtube.com/channel/UCV0vkUmvW8G1U3xmbrHHgLw" class="mr-3"><img
                                src="./assets/images/ver2/slogo.png" width="40" alt=""></a>
                        <a href="https://www.facebook.com/flinkitgame" class="mr-3"><img
                                src="./assets/images/ver2/slogo1.png" width="40" alt=""></a>
                        <a href="https://www.linkedin.com/company/flinkit-global"><img
                                src="./assets/images/ver2/slogo2.png" width="40" alt=""></a>
                    </div>
                    <div class="col-6 offset-3 text-center mt-4">
                        <small class="text-light-color">© Flinkit! 2020 All rights reserved.</small><br>
                        <small class="text-light-color">When you visit or interact with our sites, services or tools, we
                            or our authorised service providers may use cookies for storing information
                            to help provide you with a better, faster and safer experience and for marketing
                            purposes.</small>
                    </div>

                </div>

            </div>
        </div>
    </div>
</div>

<div class="modal" id="videoModal" tabindex="-1">
    <div class="modal-dialog modal-dialog-centered " style="height: initial !important;">
        <div class="modal-content">
            <div class="modal-body">
                <iframe height="400" style="width:100%;" src="https://www.youtube.com/embed/L9otgka3xGE"
                    title="Flinkit! Friends!" frameborder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowfullscreen></iframe>
            </div>
        </div>
    </div>
</div>