import { HttpClient } from '@angular/common/http';
import { Component, NgZone, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { UtilsService } from '../services/utils.service';
import { environment } from '../../environments/environment';
import Swal from 'sweetalert2';
import { EmojiServiceService } from '../services/emoji-service.service';
import { SwiperComponent } from 'ngx-useful-swiper';
import { LocaleService } from '../services/locale.service';
import { MobileAppService } from '../services/mobile-app.service';
import { LocationService } from '../services/location.service';
import Noty from 'noty';
import { StorageService } from '../services/storage.service';
declare var MobileDetect;

@Component({
  selector: 'app-museum-onboarding',
  templateUrl: './museum-onboarding.component.html',
  styleUrls: ['./museum-onboarding.component.scss']
})

export class MuseumOnboardingComponent implements OnInit {
  @ViewChild('usefulSwiper',{static: false}) usefulSwiper: SwiperComponent;
  coords;
  bgColor;
  reconnectActive = true;
  isLocation = false;
  pageConfig = {
    loginStep: 0,
    loaders: {
      joinGame: false

    }
  };
  noty;
  params;
  swiperConfig = {
    spaceBetween: 10,
    loop: false,
    initialSlide: 2,
    slidesPerView: 'auto',
    loopedSlides: 2,
    centeredSlides: true
  };
  activeGame;
  error;
  playerObj = {
    teamName: null,
    avatarIndex: 0,
    passedQuests: []
  };
  locales = {
    reconnect: this.localeService.getTrans('Reconnect'),
  }
  constructor(private utilsService: UtilsService, private route: ActivatedRoute, private http: HttpClient, private router: Router, public emojiService: EmojiServiceService, public localeService: LocaleService, private mobileAppService: MobileAppService, private locationService: LocationService, private ngZone: NgZone, private storageService: StorageService) { }

  dropNoty(type, text, timeout?) {
    this.noty = new Noty({
      type: type,
      text: text,
      killer: true,
      timeout
    }).show();
  }

  joinGame(){
    this.pageConfig.loaders.joinGame = true;
    console.log(this.activeGame)

    if(this.activeGame.isDynamicQuestionCreation){
      this.dropNoty('info', this.localeService.getTrans('Checking location data') + '<i class="las la-circle-notch ml-1 la-spin"></i>');
      this.locationService.getPosition().then((x: any)=>{
        this.ngZone.run(() => {
            if(x.coords){
              this.coords = x.coords;
              this.isLocation = true;
              this.performJoin(true);
            }    
            if(this.noty){
              this.noty.close();
            }
          });
      }, (err)=>{
        this.isLocation = false;
        this.pageConfig.loaders.joinGame = false;
        Swal.fire({
          title: this.localeService.getTrans('Cannot access location'),
          text: this.localeService.getTrans('You need to grant access to the browser to start the game.'),
          icon: 'error',
          showCancelButton: true,
          showConfirmButton: true,
          confirmButtonText: this.localeService.getTrans(`Got it`),
          cancelButtonText: 'Back',
          heightAuto: false
        });
      
      })
    } else{
      this.performJoin(false);
    }


   
  }

  getMobileDetails(){

    const md = new MobileDetect(window.navigator.userAgent)

    let mobileObj = {
      browser: md.os(),
      isIphone: md.is('iPhone'),
      phone: md.phone(),
      version: md.versionStr('Build')
    }
    return mobileObj;
   
  }

  performJoin(hasLocation?){
    return this.http.post(environment.baseUrl + 'games/joinGameWithQr', { tourId: this.params.tid,
      paymentIdentifier: this.params.pc,
      isShowCase: this.params.showcase === 'true' ? true : false,
      mobileData: this.getMobileDetails(),
      partner: this.params.uid, playerObj: this.playerObj, coords: hasLocation ? {
        lat: this.coords.latitude,
        lng: this.coords.longitude
      } : null }).subscribe((data: any) => {
      console.log(data)
      if(data.success){
        let gameData = {g: data.data._id, p: this.playerObj.teamName, partner: this.params.uid, showcase: this.params.showcase};
        this.storageService.writeStorage('fl_storage', JSON.stringify(gameData));
        this.router.navigate(['/museum-walkthrough', gameData])
      } else{
        this.utilsService.dropSwal('Oops', 'Something went wrong or the QR was already used before.', 'error')
      }

      this.pageConfig.loaders.joinGame = false;
    });
  }

  setAvatar(){
    this.playerObj.avatarIndex = this.usefulSwiper.swiper.activeIndex;
  }

  setAvatarIndex(){
    this.playerObj.avatarIndex = this.usefulSwiper.swiper.activeIndex;
  }

  reconnectToGame(){
    this.router.navigate(['/game', this.storageService.getStorage('fl_storage')])
  }

  ngOnInit() {
    if (this.utilsService.checkDeviceCompatibility()) {
      this.route.queryParams.subscribe((params) => {
        console.log(params);
        this.params = params;
        if (!params.uid) {
          window.location.href = this.mobileAppService.getURL()+'en/museums';
        }
     
        if (params.pc && params.uid && params.tid) {
         
          return this.http
            .post(
              environment.baseUrl + 'partnerPayments/getPartnerPayment',
              {
                tourId: params.tid,
                paymentIdentifier: params.pc,
                partner: params.uid,
              }
            )
            .subscribe((data: any) => {
              console.log(data);
              if (!data.success) {
                Swal.fire({
                  icon: 'info',
                  text: data.msg,
                  showDenyButton: false,
                  showCancelButton: false,
                  showConfirmButton: true,
                  confirmButtonText: this.localeService.getTrans(`Got it`),
                  confirmButtonColor: '#4E3B74',
                }).then((result) => {
                  this.router.navigate(['/museum-onboarding'], {
                    queryParams: {
                      uid: params.uid,
                    },
                   });
                })
              } else{
                this.activeGame = data.tour;
                console.log(this.activeGame, 'ac');
                this.bgColor = this.activeGame.partner ? ((this.activeGame.isCustomBranding && this.activeGame.customBranding.color) ? this.activeGame.customBranding.color : (this.activeGame.partner.color ? this.activeGame.partner.color : null)) : null;

              }
            });
        }
      });
    }
    if(this.storageService.getStorage('fl_storage')){
        this.reconnectActive = true;
    } else{
      this.reconnectActive = false;
    }
  }

}
