<div id="startPage" class="fi-modal-content-container d-flex align-items-center justify-content-center p-3 image-padding" *ngIf="game">
    <!-- Standby -->
    <div class="row w-100 d-flex align-items-center pb-5 pt-5 min-vh-100">
      <div class="col-12 text-center">
       
        <img  [src]="game.tourId.startScreen.cover"  class="img-fluid shadow" >
        <h4 class="fi-text-black mt-3">{{game.tourId.name}}</h4>
        <p class="fi-text-bold mt-2 fi-text-09"  data-aos="fade-up" [innerHTML]="getFormattedText(game.tourId.startScreen.text)" style="white-space: pre-wrap;"></p>
  
        <button class="flinkint-btn primary btn-block mt-3 fi-text-black text-white"  [style.background-color]="bgColor" [style.border-color]="bgColor"  (click)="jumpToGame()" >
            <div class="content" i18n>Start game</div>
          </button>
      </div>
    </div>
    <!-- End of Standby -->
  </div>